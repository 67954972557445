import { useEffect, useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { getArrimadores } from "../../../controllers/admin/StatisticsController";
import { BarChart } from "@mui/x-charts";

import { Bar, Chart } from 'react-chartjs-2';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
// Registrar los componentes del gráfico
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const colors = [
  "rgba(255, 127, 80, 0.5)",
  "rgba(135, 206, 250, 0.5)",
  "rgba(50, 205, 50, 0.5)",
  "rgba(255, 215, 0, 0.5)",
  "rgba(106, 90, 205, 0.5)",
  "rgba(255, 99, 71, 0.5)",
  "rgba(32, 178, 170, 0.5)",
  "rgba(255, 160, 122, 0.5)",
  "rgba(147, 112, 219, 0.5)",
  "rgba(72, 209, 204, 0.5)"
];

export default function Arrimadores()
{
    const [statistics,setStatistics] = useState([]);
    const [chartData,setChartData] = useState({labels:[],datasets:[]});

    const [startDate,setStartDate] = useState(moment().startOf('month'));
    const [endDate,setEndDate] = useState(moment().add(1, 'month').startOf('month'));
 
    useEffect(()=>{
        console.log('BUSCANDO ARRIMADORES')
        handleGetStatistics();
    },[]);

    const handleGetStatistics = async () =>{
        let response = await getArrimadores(startDate,endDate);
        if(response.success === true)
        {
            setStatistics(response.data.statistics);
            if(response.data.statistics)
            {
                const _statistics = response.data.statistics;
                const labels = _statistics.map(item=> item.proveedor);
                const values = _statistics.map(item=> item.cantidad);

                setChartData({
                    labels,
                    datasets:[{
                        label:'',
                        data: values,
                        backgroundColor: colors,//'rgba(54, 162, 235, 0.2)',
                        borderColor: colors.map(color => color.replace("0.5", "1")),//'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    }],
                    
                })
            }
        }
    }


    // Configuración de los datos para el gráfico
  /*const chartData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'], // Etiquetas del eje X
    datasets: [
      {
        label: 'Ventas Mensuales', // Etiqueta de la leyenda
        data: [30, 50, 70, 20, 100, 80], // Datos a graficar
        backgroundColor: 'rgba(75, 192, 192, 0.5)', // Color de las barras
        borderColor: 'rgba(75, 192, 192, 1)', // Color del borde
        borderWidth: 1, // Ancho del borde
      },
    ],
  };*/

  // Opciones de configuración del gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Gráfico de Arrimadores',
      },
    },
  };

    return(
        <>
        <Grid container spacing={3} mb={5} justifyContent={'center'}>
                <Grid item lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} xl={4}>
                            {/**Fecha */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha inicial"
                                        //minDate={moment.now()}
                                        
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        minDate={new Date('2024-01-01')}
                                        //maxDate={new Date()}    
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} xl={4}>
                            {/**Fecha */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha final"
                                        //minDate={moment.now()}
                                        
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        minDate={new Date('2024-01-01')}
                                        //maxDate={new Date()}    
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} xl={2}>
                            <Button
                                fullWidth 
                                sx={{height:'100%'}}
                                variant="contained"
                                onClick={()=>handleGetStatistics()}
                            >
                                <FontAwesomeIcon size="2x" icon={faSearch}/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            {
                chartData.labels.length !== 0 &&
                <Box sx={{width:'100%',height:'50vh'}}>
                    <Bar
                        data={chartData}
                        options={options}
                    />
                </Box>
            }
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            {/*
                statistics.length !== 0 &&
                <Box flex={1}>
                <BarChart
                    dataset={statistics}
                    xAxis={[{ scaleType: 'band', dataKey: 'proveedor' }]}
                    series={[{ dataKey: 'cantidad', label: 'Cantidad'}]}
                    layout="vertical"
                    grid={{ vertical: true }}
                    {...chartSetting}
                    
                />
                </Box>
            */}
        </Grid>
        </>
    )
}